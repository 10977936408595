import React from 'react';
import { Link } from 'gatsby';

export default function articlePagination(props) {
  const { currentPage, numPages } = props;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? '' : (currentPage - 1).toString();
  const nextPage = (currentPage + 1).toString();

  return (
    <div className="pagination__container">
      <ul className="pagination__list">
        <li className="pagination__item pagination__item-prev">
          {!isFirst ? (
            <Link to={`/archive/${prevPage === 0 ? '' : prevPage}`} rel="prev">
              PREVIOUS
            </Link>
          ) : (
            <span>PREVIOUS</span>
          )}
        </li>
        {Array.from({ length: numPages }, (_, i) => (
          <li
            key={`pagination-number${i + 1}`}
            className="pagination__item pagination__item-number"
          >
            <Link
              to={`/archive/${i === 0 ? '' : i + 1}`}
              className={i + 1 === currentPage ? 'active' : ''}
            >
              {i + 1}
            </Link>
          </li>
        ))}

        <li className="pagination__item pagination__item-next">
          {!isLast ? (
            <Link to={`/archive/${nextPage}`} rel="next">
              NEXT
            </Link>
          ) : (
            <span>NEXT</span>
          )}
        </li>
      </ul>
    </div>
  );
}
