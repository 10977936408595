import React from 'react';
import Layout from '@components/layout';
import ArticleGrid from '@components/article-grid';
import { graphql } from 'gatsby';
import ArticlePagination from '@components/article-pagination';

class BlogListTemplate extends React.Component {
  render() {
    const props = this.props;
    const articles = props.data.allMarkdownRemark.edges;

    const { currentPage, numPages } = props.pageContext;

    return (
      <Layout
        location={props.location}
        title="Blog Archive"
        namespace="archive"
      >
        <div className="hero hero-2">
          <div className="hero-2__background-short"></div>
          <div className="hero-2__container-short">
            <div className="title-label-1 title-lable-1--alt">
              <div className="title-label-1__count">07</div>
              <div className="title-label-1__seperator"></div>
              <div className="title-label-1__label">Archive</div>
            </div>
            <h1 className="hero-2__title">In the works</h1>
            <p className="hero-2__content">
              Dive into all the content we have shared over the years.
            </p>
          </div>
        </div>

        <section className="archived-articles">
          <ArticleGrid articles={articles} />
          <ArticlePagination currentPage={currentPage} numPages={numPages} />
        </section>
      </Layout>
    );
  }
}

export default BlogListTemplate;

export const pageQuery = graphql`
  query blogPageQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [fields___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
            date(formatString: "MMM DD, YYYY")
          }
          frontmatter {
            title
            author {
              name
              image
            }
            thumbnail
          }
        }
      }
    }
    site {
      siteMetadata {
        defaultPostThumbnail
      }
    }
  }
`;
